.wave {
  background: url("/src/assets/images/bottomwave.png") repeat-x;
  position: absolute;
  width: 5400px;
  height: 200px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  bottom: -8rem;
}

.wave:nth-of-type(2) {
  bottom: 0;
  position: absolute;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.12s infinite,
    swell 7s ease -1.25s infinite;
  opacity: 0.9;
  bottom: -8rem;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
