@font-face {
  font-family: "Quantify";
  src: url("assets/fonts/QuantifyBold.ttf");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* 드래그금지, x축스크롤금지 */
body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-x: hidden;
}
/* 스크롤바숨김 */
body::-webkit-scrollbar {
  display: none;
}
